<template>
    <div>
        <Nav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Needs to be Measured</h1>
            <Container :buildSchedule="dataState" :user="appState.user" />
        </main>
    </div>
</template>

<script>
    import Nav from '@/components/schedulers/measure_scheduler/Nav';
    import Container from '@/components/schedulers/measure_scheduler/Container';
    import { store } from '@/store/BuildScheduler.store';
    import { dataStore } from '@/store/MeasureScheduler.store';
    import buildSchedulerCache from "@/cache/buildScheduler.cache";

    export default {
        name: "MeasureSchedulerPage",
        components: {
            Nav,
            Container
        },
        data() {
            return {
                state: store.state,
                dataState: dataStore.state,
                appState: this.$appStore.state,
                cache: {
                    buildSchedulerCache
                }
            }
        },
    }
</script>

<style scoped>

</style>
