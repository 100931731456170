<template>
    <div>
        <Nav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Maintenance</h1>
            <Container :buildSchedule="state.buildSchedule" :user="appState.user" />
        </main>
    </div>
</template>

<script>
    import Nav from '@/components/schedulers/maintenance_scheduler/Nav';
    import Container from '@/components/schedulers/maintenance_scheduler/Container';
    import { store } from '@/store/BuildScheduler.store';
    import { dataStore } from '@/store/MaintenanceScheduler.store';
    import AppStore from "@/store/App.store";
    import buildSchedulerCache from "@/cache/buildScheduler.cache";

    export default {
        name: "MaintenanceSchedulerPage",
        components: {
            Nav,
            Container
        },
        data() {
            return {
                state: store.state,
                dataState: dataStore.state,
                appState: this.$appStore.state,
                cache: {
                    buildSchedulerCache
                }
            }
        },
        created() {
            if (AppStore.state.user.userID in this.cache.buildSchedulerCache.DEFAULT_SCHEDULER_LOCATION){
                this.state.formFields.cdiLocationID.val = this.cache.buildSchedulerCache.DEFAULT_SCHEDULER_LOCATION[AppStore.state.user.userID];
            } else {
                this.state.formFields.cdiLocationID.val = AppStore.state.user.schedulerLocation
            }

            this.dataState.formFields.cdiLocationID = this.$appStore.state.user.schedulerLocation;
            dataStore.getData();
        }
    }
</script>

<style scoped>

</style>
